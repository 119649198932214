export default {
  app: 'Verificado pelo aplicativo',
  reviews: 'revisões',
  downloads: 'Downloads',
  rated: 'Classificado para',
  play: 'Jogar',
  install: 'instalação',
  removed: 'O aplicativo foi removido',
  share: 'Compartilhar',
  wishlist: 'Adicionar à lista de desejos',
  about: 'Sobre este aplicativo',
  updated: 'Atualizado em',
  data: 'Segurança dos dados',
  safety:
    'A segurança começa com a compreensão de como os desenvolvedores coletam e compartilham seus dados. As práticas de privacidade e segurança de dados podem variar de acordo com seu uso, região e idade. O desenvolvedor forneceu essas informações e poderá atualizá-las com o tempo.',
  parties: 'Nenhum dado compartilhado com terceiros',
  sharing:
    'Saiba mais sobre como os desenvolvedores declaram o compartilhamento',
  types: 'Este aplicativo pode coletar esses tipos de dados',
  location: 'Localização, Informações pessoais e 5 outros',
  transit: 'Os dados são criptografados em trânsito',
  request: 'Você pode solicitar que os dados sejam excluídos',
  details: 'Ver detalhes',
  Games: 'Jogos',
  Apps: 'Aplicativos',
  Movies: 'Filmes',
  Books: 'Livros',
  Kids: 'Crianças',
  now: 'agora',
  environmental: 'Detecção bem-sucedida',
  detecting: 'Detecção do ambiente de instalação...',
  found: 'Aplicativo não encontrado',
  installed: 'Instalado com sucesso', // 已成功安装
  experience:
    'Localize o ícone do aplicativo na sua tela inicial e toque em Executar para desfrutar de uma experiência mais conveniente e tranquila.', // 请在您的主屏幕上找到应用图标并点击运行，享受更加便捷流畅的体验。
  name: 'Nome do aplicativo', // 应用名称
  Mobile: 'Ícones móveis', // 手机端图标
  icon: 'Ícone do PC', // PC端图标
  noSupport: 'Seu navegador não suporta pwa',
  noDownloadUrl: 'Você não configurou o endereço de download do aplicativo',
  unregister: 'Desinstale o aplicativo na área de trabalho primeiro',
  noFindApp: 'Aplicativo não encontrado',
  cancelDownload: 'Você cancelou a instalação!',
  failDownload: 'A instalação falhou!',
  purchases: 'Contém anúncios · Compras no app'
}
