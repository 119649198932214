export default {
  app: 'Verified by App',
  reviews: 'reviews',
  downloads: 'Downloads',
  rated: 'Rated for',
  play: 'Play',
  install: 'Instalar',
  removed: 'The app has been removed',
  share: 'Share',
  wishlist: 'Add to wishlist',
  about: 'About this app',
  updated: 'Updated on',
  data: 'Data safety',
  safety:
    'Safety starts with understanding how developers collect and share your data. Data privacy and security practices may vary based on your use,region, and age. The developer provided this information and may update it over time.',
  parties: 'No data shared with third parties',
  sharing: 'Learn more about how developers declare sharing',
  types: 'This app may collect these data types',
  location: 'Location, Personal info and 5 others',
  transit: 'Data is encrypted in transit',
  request: 'You can request that data be deleted',
  details: 'See details',
  Games: 'Games',
  Apps: 'Apps',
  Movies: 'Movies',
  Books: 'Books',
  Kids: 'Kids',
  now: 'Now',
  environmental: 'Successful environmental testing',
  detecting: 'Detecting the installation environment...',
  found: 'Application not found',
  installed: 'Successfully installed', // 已成功安装
  experience:
    'Please find the app icon on your home screen and tap Run to enjoy a more convenient and smooth experience.', // 请在您的主屏幕上找到应用图标并点击运行，享受更加便捷流畅的体验。
  name: 'Application Name', // 应用名称
  Mobile: 'Mobile Icons', // 手机端图标
  icon: 'PC Icon', // PC端图标
  noSupport: 'Your browser does not support pwa',
  noDownloadUrl: 'You have not configured the download address for the App',
  unregister: 'Please uninstall the desktop app',
  noFindApp: 'Application not found',
  cancelDownload: 'You have cancelled the installation!',
  failDownload: 'Installation failed!',
  purchases: 'Contains ads · In-app purchases'
}
