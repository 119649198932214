import { routes } from './routes'
import { useRoutes } from 'react-router-dom'
import './App.css'
import { SpinLoading } from 'antd-mobile'
import {Suspense, useEffect} from 'react'
import i18n from '@/i18n'
import localforage from 'localforage';

localforage.config({
  driver: localforage.INDEXEDDB, // 使用 IndexedDB 作为存储驱动
  name: 'localforage-database',
  version: 1.0,
  storeName: 'localforage-store' // 存储名称
});


function App() {
  const params = new URLSearchParams(window.location.search)
  const route = useRoutes(routes)
  const language = params.get('language') || 'pt'
  i18n.changeLanguage(language)
  localStorage.setItem('language', language)

  console.log('App rendering');

  useEffect(() => {
    console.log('App mounted');
    return () => {
      console.log('App unmounted');
    };
  }, []);

  return (
    <div className="App flex justify-center">
      <Suspense fallback={<SpinLoading color="primary"></SpinLoading>}>
        {route}
      </Suspense>
    </div>
  )
}

export default App
