import React from 'react'
import { RouteObject } from 'react-router-dom'

const DownLoad = React.lazy(() => import('../views/DownLoad'))
const Index2 = React.lazy(async () => {
  try {
    console.log('Index2 is being loaded');
    return await import('../views/Index2');
  } catch (error) {
    console.error('Error loading Index2:', error);
    throw error;
  }
});
const Home = React.lazy(() => import('../views/Home'))
const Message = React.lazy(() => import('../views/Message'))
const Login = React.lazy(() => import('../views/Login'))
const Lead = React.lazy(() => import('../views/Lead'))
const Installed = React.lazy(() => import('../views/Installed'))

export const routes: RouteObject[] = [
  {
    path: ':prefix/',
    element: <DownLoad />
  },
  {
    path: ':prefix',
    element: <DownLoad />
  },
  {
    path: ':prefix/remote',
    element: <Index2 />
  },
  {
    path: ':prefix/home',
    element: <Home />
  },

  {
    path: ':prefix/message',
    element: <Message />
  },
  {
    path: ':prefix/login',
    element: <Login />
  },

  // 引导页面
  {
    path: ':prefix/lead',
    element: <Lead />
  },

  {
    path: ':prefix/installed',
    element: <Installed />
  }
]