import { initializeApp } from 'firebase/app'
import { Dialog, Toast } from 'antd-mobile'

import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { firebaseConfig, vapidKey } from './config'
export const STORAGE_TOKEN_KEY = 'FCM_TOKEN_KEY'

initializeApp(firebaseConfig)

const messaging = getMessaging()

//   `messaging.onBackgroundMessage` handler.
onMessage(messaging, (payload) => {
  console.log('---- [main.ts] onMessage ----', payload)
  Dialog.alert({
    title: payload.data!.title,
    content: payload.data!.body
  })
})

export async function resetUI() {
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  return getToken(messaging, { vapidKey })
    .then((currentToken) => {
      console.log('【消息推送】 getToken ----->  ', currentToken)
      if (currentToken) {
        // sendTokenToServer(currentToken)
        localStorage.setItem(STORAGE_TOKEN_KEY, currentToken)
      } else {
        // Show permission request.
        console.log(
          'No registration token available. Request permission to generate one.'
        )
        // Show permission UI.
        localStorage.setItem(STORAGE_TOKEN_KEY, '')
      }
    })
    .catch((err) => {
      console.log('---- 【消息推送】 获取消息token失败 ----  ', err)
      // setTokenSentToServer(false)
      localStorage.setItem(STORAGE_TOKEN_KEY, '')
    })
}

// function requestPermission() {
//   console.log('---- Requesting permission... ----')
//   Notification.requestPermission().then((permission) => {
//     if (permission === 'granted') {
//       console.log('Notification permission granted.')
//       // TODO(developer): Retrieve a registration token for use with FCM.
//       // In many cases once an app has been granted notification permission,
//       // it should update its UI reflecting this.
//       resetUI()
//     } else {
//       console.log('---- Unable to get permission to notify. -----')
//     }
//   })
// }

// requestPermission()

// resetUI()
