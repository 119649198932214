export const firebaseConfig = {
  // Your web app's Firebase configuration here
  // See https://firebase.google.com/docs/web/setup#add-sdks-initialize
  apiKey: 'AIzaSyC04i_aEjoyRvC4uTEZ3gZSu84BKbqv6pY',
  authDomain: 'webapp-c875c.firebaseapp.com',
  projectId: 'webapp-c875c',
  storageBucket: 'webapp-c875c.appspot.com',
  messagingSenderId: '322835072074',
  appId: '1:322835072074:web:8c3d3017afd278a876d2b3',
  measurementId: 'G-LPSF837VYT'
}

export const vapidKey =
  'BHTrVkecbi1Ko0qtcSTozQJu50E5vxMi_0iF0RjLtqfbhBLSoKX3rD9VSMkTeLHV17AchYRSQ0e5xIiYPvuAjN4'
